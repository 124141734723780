// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-csgo-pricing-js": () => import("./../../../src/pages/CSGO_pricing.js" /* webpackChunkName: "component---src-pages-csgo-pricing-js" */),
  "component---src-pages-dota-pricing-js": () => import("./../../../src/pages/DOTA_pricing.js" /* webpackChunkName: "component---src-pages-dota-pricing-js" */),
  "component---src-pages-dotapaymentsuccess-js": () => import("./../../../src/pages/dotapaymentsuccess.js" /* webpackChunkName: "component---src-pages-dotapaymentsuccess-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-qrcode-js": () => import("./../../../src/pages/qrcode.js" /* webpackChunkName: "component---src-pages-qrcode-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms_of_service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-ticker-js": () => import("./../../../src/pages/ticker.js" /* webpackChunkName: "component---src-pages-ticker-js" */),
  "component---src-pages-widget-js": () => import("./../../../src/pages/widget.js" /* webpackChunkName: "component---src-pages-widget-js" */)
}

