import { createStore as reduxCreateStore } from 'redux'

const reducer = (state, action) => {
  if (action.type === `SET_CS_ACTIVE`) {
    return Object.assign({}, state, {
      activeTab: 'cs',
    })
  } else if (action.type === `SET_LOL_ACTIVE`) {
    return Object.assign({}, state, {
      activeTab: 'lol',
    })
  } else if (action.type === `SET_DOTA_ACTIVE`) {
    return Object.assign({}, state, {
      activeTab: 'dota',
    })
  } else if (action.type === `SET_MEDIA_ACTIVE`) {
    return Object.assign({}, state, {
      activeWidgetTab: 'media',
    })
  } else if (action.type === `SET_LANDING_PAGE_TRUE`) {
    return Object.assign({}, state, {
      onLandingPage: true,
    })
  } else if (action.type === `SET_LANDING_PAGE_FALSE`) {
    return Object.assign({}, state, {
      onLandingPage: false,
    })
  } else if (action.type === `TABS_LOADED`) {
    return Object.assign({}, state, {
      tabsLoaded: true,
    })
  } else if (action.type === `WIDGET_TABS_LOADED`) {
    return Object.assign({}, state, {
      widgetTabsLoaded: true,
    })
  }
  return state
}

const initialState = {
  activeWidgetTab: 'media',
  activeTab: 'cs',
  onLandingPage: true,
  tabsLoaded: false,
  widgetTabsLoaded: false,
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
